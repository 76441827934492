<template>
  <div class="recordDetail">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDio">
        <el-form ref="dioform" :model="inform" label-width="90px" size="small">
          <el-row>
            <el-col :span="12">
            <el-form-item label="任务名称:">{{ inform.inspectName }}</el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="完成状态:">
              <span v-if="inform.status=='0'" class="error">未完成</span>
              <span v-else class="success">已完成</span>
            </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="任务描述:">{{ inform.inspectRemark }}</el-form-item>
          <template v-if="inform.inspectItemRecords.length>0">
          <div class="item" v-for="(item,index) in inform.inspectItemRecords">
            <el-form-item label="巡检项目:">{{item.itemName}}</el-form-item>
            <el-form-item label="判定结果:">{{item.diagnoseText}}</el-form-item>
            <el-form-item label="拍照:">
              <el-image v-for="(imgItem,index2) in item.fileArr" :key="index2" style="width: 100px; height: 100px" :src="imgItem" :preview-src-list="item.fileArr"></el-image>
            </el-form-item>
            <el-form-item label="备注说明:">{{item.remark}}</el-form-item>
          </div>
          </template>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        inspectName:'',//巡检名称
        inspectRemark:'',//描述
        status:'',//状态
        inspectItemRecords:[],//巡检项
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    async init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '800px'
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'detail'){
          vm.dioTit = '查看巡检记录详情'
          vm.inspectRecordDetail(item.id)
        }
      })
    },
    //获取场景详情
    async inspectRecordDetail(id){
      const res = await apiUrl.inspectRecordDetail(id)
      if(res?.code==200){
        let arr = res.data.inspectItemRecords
        if(arr?.length>0){
          for(let i in arr){
            if(arr[i].imgUrlList?.length>0){
              arr[i].fileArr = []
              for(let x in arr[i].imgUrlList){
                let url = `${globalStr}/fileView${arr[i].imgUrlList[x]}`
                arr[i].fileArr.push(url)
              }
            }
          }
        }
        vm.inform = {
          inspectName: res.data.inspectName,//巡检名称
          inspectRemark: res.data.inspectRemark,//描述
          status: res.data.status,//状态
          inspectItemRecords:arr,//巡检项
        }
      }else{
        vm.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        // vm.$refs[formEl].resetFields()
      // })
      vm.inform = {
        id:'',
        inspectName:'',//巡检名称
        inspectRemark:'',//描述
        status:'',//状态
        inspectItemRecords:[],//巡检项
      }
      vm.isDio = false
    },
  }
}
</script>
<style lang='scss'>
.recordDetail{
  .contDio{
    max-height: 500px;
    overflow: auto;
    .item{
      border: 1px solid #00c6ff;
      border-radius: 6px;
      margin-bottom: 10px;
      .el-image{
        margin: 0 10px 10px 0;
      }
    }
  }
}
</style>